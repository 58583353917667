<!-- partial:partials/_footer.html -->
<footer class="footer">
  <div class="card">
    <div class="card-body">
      <div class="d-sm-flex justify-content-center justify-content-sm-between">
        <span class="text-muted text-center text-sm-left d-block d-sm-inline-block"><a href="https://bib.umontreal.ca/" target="_blank">© 2024 {{'app-footer-link'| translate}} </a>  </span>
      </div>
    </div>
  </div>
</footer>
