<div class="card mt-5">
  <div class="card-body">
    <div class="row">
      <div class="col-md-8">
        <h2 class="mt-1">{{'list-collection'| translate}}</h2>
      </div>
        <div class="col-md-4">
          <mat-card-actions align="end">
            <button class="btn btn-outline-secondary btn-sm ml-1 float-right"  (click)="backClicked()" ><i class="typcn typcn-chevron-left menu-icon"></i> {{ 'btn.return' | translate}}</button>
            <a class="btn btn-success btn-sm ml-1 float-right"  href="/collection/add" ><i class=" typcn typcn-download"></i> {{ 'ajouter' | translate}}</a>
          </mat-card-actions>
        </div>
      </div>
  </div>
</div>
<div class="card mt-1">
  <div class="card-body">
    <div class="row">
      <mat-form-field class=" w-50 mt-2">
        <input (keyup)="applyFilter(getValue(texteFiltre.value))" class="mat-input-placeholder form-control"
               matInput  id="textFiltre" name="textFiltre" #texteFiltre value="" placeholder="{{ 'filtre-text' | translate}}">
        <a href="javascript:;" *ngIf="texteFiltre.value" ><span aria-hidden="true"  (click)="viderFiltre()">&times;</span></a>
      </mat-form-field>
      <div class="table-responsive mt-3">
        <mat-table
          [dataSource]="dataSource"
          class=" w-100"
          matSort
          matSortActive="nom"
          matSortDirection="asc">

          <!-- ID Column -->
          <ng-container matColumnDef="numero">
            <mat-header-cell  *matHeaderCellDef #numero mat-sort-header  class=" mat-w-5 pl-2 "> {{ 'nr' | translate}} </mat-header-cell>
            <mat-cell *matCellDef="let row" class=" mat-w-5 pl-2" > {{row.numero}} </mat-cell>
          </ng-container>

          <!-- titre Column -->
          <ng-container matColumnDef="nom">
            <mat-header-cell  *matHeaderCellDef #titre mat-sort-header class="mat-w-75 "> {{ 'titre' | translate}} </mat-header-cell>
            <mat-cell  *matCellDef="let row" class="mat-w-75" > {{row.nom}} </mat-cell>
          </ng-container>

          <!-- date derniere mise a jour Column -->
          <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef mat-sort-header  class="mat-w-10"> {{ 'date-mise-a-jour' | translate}} </mat-header-cell>
            <mat-cell  *matCellDef="let row" class="mat-w-10"> {{row.lastDateModif | date:'short': 'fr'}} </mat-cell>
          </ng-container>

          <!-- Consulter Column -->
          <ng-container matColumnDef="consulter" >
            <mat-header-cell *matHeaderCellDef   class="mat-w-10 text-center"> {{ 'details' | translate}} </mat-header-cell>
            <mat-cell  *matCellDef="let row" class="mat-w-10 text-center">
              <button type="button" class="btn btn-primary btn-rounded btn-icon btn-sm" title="Consulter la fiche"  routerLink="/collection/{{ row.id_collection }}">
                <i class="typcn typcn-document-text menu-icon"></i>
              </button>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;">
          </mat-row>
        </mat-table>

        <mat-paginator [pageSizeOptions]="[20, 60, 90, 120]"></mat-paginator>
      </div>
    </div>
  </div>
</div>
