<div class="card mt-5 mb-1">
  <div class="card-body">
    <div class="row">
      <div class="col-md-9">
        <h2 class="mt-1" *ngIf="conditionIdItem">{{ 'form-values' | translate}}
          <span  > | {{ 'id' | translate}}</span>
        </h2>
        <h2 class="mt-1" *ngIf="!conditionIdItem">{{ 'new-item' | translate}}</h2>
      </div>
      <div class="col-md-3"><button *ngIf="conditionIdItem" class="btn btn-warning text-white btn-sm ml-1 float-right"   (click)="addToPanier(item)" ><i class="typcn typcn-shopping-cart menu-icon"></i> {{ 'btn.ajouter-panier' | translate}}</button>
        <button class="btn btn-outline-secondary btn-sm ml-1 float-right"  (click)="backClicked()" ><i class="typcn typcn-chevron-left menu-icon"></i> {{ 'btn.return' | translate}}</button>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12 grid-margin stretch-card">
    <div class="card">
      <!-- Alert notification div -->
      <div *ngIf="showAlert" class="alert alert-success" role="alert" id="alert-modif">
        {{ 'message.action-text' | translate}}
        <button type="button"  class="close" (click)="global.nonAfficher('alert-modif')" >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="card-body mb-5">
        <form class="forms-sample"  #itemForm="ngForm" name="itemForm" id="itemForm"   (ngSubmit)="onSubmit(itemForm)" >
          <div class="row">
            <div class="col-md-6">
              <label for="titre">{{ 'titre' | translate}} <span class="text-danger">*</span></label>
              <input  [ngClass]="{ 'form-control':true,'error-class': !global.obligatoireChamp(item.titre),'valid-class': global.obligatoireChamp(item.titre)}" #titre="ngModel" name="titre" id="titre" placeholder="{{ 'titre' | translate}}" [(ngModel)]="item.titre" >
              <input  class="form-control" #idItem="ngModel" name="idItem" id="idItem" type="hidden" [(ngModel)]="item.idItem">
              <input  class="form-control"  name="action" id="action" type="hidden"  value="{{action}}"  >
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="typeDocument">{{'typeDocument'| translate }} <span class="text-danger">*</span></label>
                <select class="form-control" id="typeDocument" name="typeDocument" #typeDocument [(ngModel)]="item.typeDocument">
                  <option *ngFor="let rep of this.lstOptions.lstTypeDocument" [value]="rep.id">{{rep.name}}</option>
                </select>
              </div>
            </div>
            <div class="col-md-2">
              <label for="auteur">{{ 'auteur' | translate}} </label>
              <input  class="form-control" #auteur="ngModel" name="auteur" id="auteur" type="text" [(ngModel)]="item.auteur">
            </div>
            <div class="col-md-2">
              <label for="annee">{{ 'annee-pub' | translate}} </label>
              <select name="annee" id="annee"  class="form-control" #annee [(ngModel)]="item.annee" >
                <option *ngFor="let rep of this.arrayAnnee" >{{rep}}</option>
              </select>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-3">
              <label for="idColecttion">{{ 'collection' | translate}} <span class="text-danger">*</span></label>
              <select name="idColecttion" id="idColecttion"  [ngClass]="{ 'form-control':true,'error-class': !global.obligatoireChamp(item.idColecttion),'valid-class': global.obligatoireChamp(item.idColecttion)}" #idColecttion  [(ngModel)]="item.idColecttion">
                <option *ngIf="!item.idColecttion" value="">{{'choisissez' | translate}}</option>
                <option *ngFor="let rep of listeCollection" [value]="rep.id" >{{rep.nom}}</option>
              </select>
            </div>
            <div class="col-md-3">
              <label for="auteur">{{ 'editeur' | translate}} </label>
              <input  class="form-control" #editeur="ngModel" name="editeur" id="editeur" type="text" [(ngModel)]="item.editeur">
            </div>
            <div class="col-md-3">
              <label for="edition">{{ 'edition' | translate}} </label>
              <input  class="form-control" #edition="ngModel" name="edition" id="edition" type="text" [(ngModel)]="item.edition">
            </div>
            <div class="col-md-3">
              <label for="isbn">{{ 'isbn' | translate}} </label>
              <input  class="form-control" #isbn="ngModel" name="isbn" id="isbn" type="text" [(ngModel)]="item.isbn">
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-3">
              <div class="form-group">
                <label for="langue">{{'langue'| translate }} </label>
                <select class="form-control" id="langue" name="langue" #langue [(ngModel)]="item.langue">
                  <option *ngFor="let rep of this.lstOptions.lstLangue" [value]="rep.id">{{rep.name}}</option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="format">{{'format'| translate }} </label>
                <select class="form-control" id="format" name="format" #format [(ngModel)]="item.format">
                  <option *ngFor="let rep of this.lstOptions.lstFormatSubstitut" [value]="rep.id">{{rep.name}}</option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="format">{{'visuelAccessibles'| translate }} </label>
                <select class="form-control" id="visuelAccessibles" name="visuelAccessibles" #visuelAccessibles [(ngModel)]="item.visuelAccessibles">
                  <option *ngFor="let rep of this.lstOptions.lstVisuelAccessible" [value]="rep.id">{{rep.name}}</option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="documentComplet">{{'documentComplet'| translate }} </label>
                <select class="form-control" id="documentComplet" name="documentComplet" #documentComplet [(ngModel)]="item.documentComplet">
                  <option *ngFor="let rep of this.lstOptions.lstDocComplet" [value]="rep.name">{{rep.name}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row mb-3 toggle-container" [class.show]="item.documentComplet == 'Oui'">
            <div class="col-md-12">
              <label for="description">{{ 'description' | translate }}</label>
              <input class="form-control" #description name="description" id="description" type="text" [(ngModel)]="item.description">
            </div>
          </div>
          <!-- Menu onglets-->
          <div class="mb-5">
            <mat-tab-group animationDuration="2000ms">
            <mat-tab label="{{ 'file-item' | translate}}">
              <div class="form-group" *ngIf="(!isFile)">
              <mat-card-content>
                  <br><br>
                  <mat-form-field style="width: 100%;">
                    <mat-label>{{ 'file-uploud' | translate}}</mat-label>
                    <button mat-icon-button matPrefix type="button" (click)="f_input.click()">
                      <mat-icon class="f-20 mr-2">attach_file</mat-icon>
                    </button>
                    <input type="text" readonly matInput [formControl]="display" id="label-name-file" />
                    <input
                      type="file"
                      [accept]="[
                       '.pdf', '.doc', '.docx', '.txt', '.jpg', '.jpeg', '.gif', '.png',
                       '.svg', '.xls', '.xlsx', '.csv', '.pptx', '.ppt', '.rtf', '.mp3',
                       '.wav', '.ogg', '.zip'
                        ]"
                      id="f_input"
                      required="false"
                      hidden
                      #f_input
                      (change)="handleFileInputChange(f_input.files)"
                    />
                    <mat-hint
                    >{{ 'accept-file' | translate}}
                    </mat-hint>
                    <mat-error>{{ 'file-required' | translate}}</mat-error>
                  </mat-form-field>
                  <br><br>
                </mat-card-content>
              </div>
              <div *ngIf="(isFile) && isFileLoading"  class="loading-container" >
                <div class="dots">
                  <span class="dot"></span>
                  <span class="dot"></span>
                  <span class="dot"></span>
                </div>
              </div>
              <mat-card *ngIf="(isFile) && (!isFileLoading)" id="img-collection" class="mt-5 mb-5">
                <mat-card-content>
                  <div class="form-group">
                    <div class="input-group">
                    <span class="input-group-addon hidden-xs linkname">
                      <a *ngIf="isFile" (click)="downloadAndOpen(item.file, item.URL)" class="" href="javascript:void(0)">
                         <span> {{ 'voir-fichier' | translate }}</span>
                      </a>
                    </span>
                      <!--<span id="copyButton" class="input-group-addon btn" title="{{ 'click-modif' | translate}}" (click)="global.copyInputValue(fichierURL)">
                    <i class="typcn typcn-clipboard menu-icon"></i>
                  </span>-->
                       <input type="text"  name="fichierURL" id="fichierURL" #fichierURL value="{{item.file}}" class="form-control"  readonly>
                     </div>
                   </div>
                  <br>
                  <button mat-button color="warn" (click)="deleteFile(item.file,item.URL)" type="button"><mat-icon>delete</mat-icon> {{ 'supprimer-file' | translate}}</button>
                 </mat-card-content>
                   <!--<button mat-button color="primary" (click)="updateURL(item.idItem,item.URL)" type="button"><mat-icon>cached</mat-icon> {{ 'mise-a-jour-url' | translate}}</button>-->


              </mat-card>
              <div *ngIf="isLoading">
                <p>Téléchargement en cours : {{ uploadProgress }}%</p>
                <div class="progress-bar">
                  <div class="progress" [style.width.%]="uploadProgress"></div>
                </div>
              </div>
              <input type="hidden" id="URL" name="URL" #URL="ngModel"  [(ngModel)]="item.URL" class="form-control"  >
              <input  class="form-control mb-3" #image="ngModel" name="file" id="file" type="hidden" [(ngModel)]="item.file">
            </mat-tab>
            <mat-tab label="{{ 'note-item' | translate}}">
              <div class="form-group mt-4 mb-4">
                <textarea class="form-control" id="note" name="note" #note="ngModel" rows="5" [(ngModel)]="item.note"></textarea>
              </div>
            </mat-tab>
            <mat-tab label="{{ 'liste-demandes' | translate}}" *ngIf="item.idItem">
              <div class="table-responsive mt-3 pt-5" *ngIf="tableauSuivi.length!=0">
                <table class="table table-hover table-bordered">
                  <thead>
                  <tr>
                    <th class="w-5">{{ 'numero' | translate}}</th>
                    <th>{{ 'nom' | translate}}</th>
                    <th>{{ 'prenom' | translate}}</th>
                    <th>{{ 'courriel' | translate}}</th>
                    <th>{{ 'statut' | translate}}</th>
                    <th>{{ 'dateActivation' | translate}}</th>
                    <th>{{ 'dateExpiration' | translate}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let rep of tableauSuivi" >
                    <td>{{rep.numero}}</td>
                    <td>{{rep.nom}}</td>
                    <td>{{rep.prenom}}</td>
                    <td>{{rep.courriel}}</td>
                    <td>{{rep.statut}}</td>
                    <td>{{ rep.dateActivation  }}</td>
                    <td>{{ rep.dateExpiration  }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <!-- fin archives-->
            </mat-tab>
          </mat-tab-group>
          </div>
          <mat-divider></mat-divider>
          <button type="button" class="btn btn-success mr-2 mt-4" data-toggle="modal" data-target="#modal-enregistrer" (click)="handleSubmit()"><i class="fa fa-save btn-icon-prepend"></i> {{bouttonAction}}</button>
          <button *ngIf="conditionIdItem" type="button" class="btn btn-danger mr-2 mt-4" data-toggle="modal" data-target="#modal-supprimer" ><i class="fa fa-trash btn-icon-prepend"></i> {{'btn.supprimer'| translate}}</button>
          <!-- Modal pour modifier-->
          <div class="modal fade" id="modal-enregistrer" tabindex="-1" role="dialog" aria-labelledby="modal-enregistrer" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h2 class="modal-title" id="exampleModalLabel">{{'message.confirmer-titre'| translate}}</h2>
                  <button type="button" class="close" #closebutton data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div class="modal-body">
                  <h3>{{'message.confirmer-text'| translate}}</h3>
                </div>
                <div class="modal-footer">
                  <button *ngIf="ifAdmin" type="submit" class="btn btn-success" >{{bouttonAction}}</button>
                  <button type="button" class="btn btn-secondary" data-dismiss="modal"> <i class="icon-close btn-icon-prepend"></i> {{ 'annuler' | translate}}</button>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal pour supprimer-->
          <div class="modal fade" id="modal-supprimer" tabindex="-1" role="dialog" aria-labelledby="modal-enregistrer" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h2 class="modal-title" id="modal-supprimer-Label">{{'message.confirmer-titre'| translate}}</h2>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div class="modal-body">
                  <h3>{{'message.supprimer-text'| translate}}</h3>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal"> <i class="icon-close btn-icon-prepend"></i> {{'btn.annuler'| translate}}</button>
                  <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="delete(item.idItem,item.file,item.URL)"><i class="fa fa-trash btn-icon-prepend"></i> {{'btn.supprimer'| translate}}</button>
                </div>
              </div>

            </div>
          </div>
          <!-- fin modal-->
        </form>
      </div>
    </div>
  </div>
</div>
<div class="noVisible" *ngFor="let item of items$ | async"></div>
