<div class="row mt-5" >
  <div class="card mt-5 p-4">
    <div class="drop_box_alert mt-5 mb-5 p-5 text-center">
      <span class="display-1 font-size-5 d-block mt-5 mb-2">401</span>
      <h1 class="text-danger">{{ 'not-user-title' | translate}}</h1>
      <h2 class="font-weight-light">{{ 'fichier.not-access-explication' | translate}}</h2>
      <a  routerLink="/accueil" class="btn btn-outline-dark btn-fw mb-5 mt-4">{{ 'not-user-lien' | translate}}</a><br><br>
    </div>
  </div>
</div>
