<div class="card mt-5 mb-1">
  <div class="card-body">
    <div class="row">
      <div class="col-md-9">
        <h2 class="card-title">{{ sujetItem}}</h2>
      </div>
      <div class="col-md-3">
        <button class="btn btn-outline-secondary btn-sm ml-1 float-right"  (click)="backClicked()" ><i class="typcn typcn-chevron-left menu-icon"></i> {{ 'btn.return' | translate}}</button>
      </div>
    </div>
  </div>
</div>
<div class="row mt-1">
  <div class="col-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body mb-5">
        <!-- Alert notification div -->
        <div class="alert alert-success alert-dismissible fade show cacherVisible" role="alert" id="alert-add">
          {{ 'message.action-text' | translate}}
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <!-- Alert notification div -->
        <div class="alert alert-warning alert-dismissible fade show cacherVisible" role="alert" id="alert-sup">
          {{ 'message.action-suppression' | translate}}
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form  #panierForm="ngForm"  name="panierForm" id="panierForm" (ngSubmit)="onSubmit(panierForm)" >
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label for="sujet">{{'sujet'| translate }} <span class="text-danger">*</span></label>
                <input  type="text" class="form-control" id="sujet" name="sujet"  #sujet  [(ngModel)]="panier.sujet" >
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="nom">{{ 'nom' | translate}} <span class="text-danger">*</span></label>
                <input type="text"  class="form-control" id="nom" name="nom" #nom   [(ngModel)]="panier.nom" >
                <input type="hidden" class="form-control" id="idPanier" name="idPanier" [(ngModel)]="panier.idPanier" >
                <input  class="form-control"  name="action" id="action" type="hidden"  value="{{action}}"  >
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="prenom">{{'prenom'| translate }} <span class="text-danger">*</span></label>
                <input type="text"  class="form-control" id="prenom" name="prenom" #prenom [(ngModel)]="panier.prenom" >
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="courriel">{{'courriel'| translate }} <span class="text-danger">*</span></label>
                <input type="email"  class="form-control" id="courriel" name="courriel" #courriel [(ngModel)]="panier.courriel">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label for="statut">{{'statut'| translate }} <span class="text-danger">*</span></label>
                <select class="form-control" id="statut" name="statut" #statut [(ngModel)]="panier.statut">
                  <option value="Actif"  >Actif</option>
                  <option value="Inactif">Inactif</option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label >{{ 'dateActivation' | translate}} <span class="text-danger">*</span></label>
                <div class="input-group">
                  <input class="form-control ngbfield"  [placeholder]="placeholderDateAc"
                         id="dateActivation"
                         name="dateActivation" [readonly]="true" [(ngModel)]="panier.dateActivation"
                         ngbDatepicker  #d1="ngbDatepicker" required>
                  <div class="input-group-append">
                    <button
                      class="btn btn-dark" (click)="d1.toggle()"
                      type="button"><i class="typcn typcn-calendar-outline"></i></button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="nbrJours">{{'nbr-jours'| translate }} <span class="text-danger">*</span></label>
                <select class="form-control" id="nbrJours" name="nbrJours" #nbrJours [(ngModel)]="panier.nbrJours">
                  <option *ngFor="let rep of this.listeChoixOptions.listNbrJoursValides" [value]="rep.id">{{rep.name}}</option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label >{{ 'dateExpiration' | translate}} <span class="text-danger">*</span></label>
                <div class="input-group">
                  <input class="form-control ngbfield"  [placeholder]="placeholderDateEx"
                         id="dateExpiration"
                         name="dateExpiration" [readonly]="true" [(ngModel)]="panier.dateExpiration"
                         ngbDatepicker  #d2="ngbDatepicker" required>
                  <div class="input-group-append">
                    <button
                      class="btn btn-dark" (click)="d2.toggle()"
                      type="button"><i class="typcn typcn-calendar-outline"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" >
            <div class="col-md-5" >
              <div class="form-group " >
                <label for="cle">{{'cle'| translate }} </label>
                <input type="text" class="form-control " id="cle" name="cle"  #cle [(ngModel)]="panier.cle"  readonly>
              </div>
            </div>
            <div class="col-md-1 mt-4 pt-2">
              <div class="dropdown show ">
                <a class="btn btn-dark btn-lg dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{ 'btn.actions' | translate}}
                </a>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <a class="dropdown-item" style="cursor: pointer;" [cdkCopyToClipboard]="routeUrl+'/lien/'+panier.cle">{{ 'copier-url' | translate}}</a>
                  <a class="dropdown-item" style="cursor: pointer;" (click)="modifierCle();">{{ 'modif-cle' | translate}}</a>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="note">{{ 'note' | translate}}</label>
                <input type="text"  class="form-control" id="note" name="note" #note [(ngModel)]="panier.note" >
              </div>
            </div>
          </div>
          <!-- Modal pour modifier-->
          <div class="modal fade" id="modal-enregistrer" tabindex="-1" role="dialog" aria-labelledby="modal-enregistrer" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h2 class="modal-title" id="exampleModalLabel">{{'message.confirmer-titre'| translate }}</h2>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closebutton>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div class="modal-body">
                  {{'message.confirmer-text'| translate }}
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary btn-sm"  (click)="onSubmit(this.panierForm);onFermeModal();"><i class="typcn typcn-tick"></i> {{'btn.enregistrer'| translate}}</button>
                  <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal"> <i class=" typcn typcn-times"></i>{{'btn.annuler'| translate}}</button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <hr>
        <div class="row" *ngIf="listePanierDetails.length!=0">
          <div class="col-lg-12 pb-2">
            <h4>Nombre Items : <strong>{{listePanierDetails.length}} </strong></h4>
          </div>
          <div class="col-lg-12 pl-3 pt-3" >
            <table class="table table-hover table-bordered bg-white">
              <thead>
              <tr>
                <th class="w-5">{{ 'numero' | translate}}</th>
                <th>{{ 'item_titre' | translate}}</th>
                <th>{{ 'item_auteur' | translate}}</th>
                <th>{{ 'item_editeur' | translate}}</th>
                <th>{{ 'date' | translate}}</th>
              </tr>
              <tr *ngFor="let rep of listePanierDetails" >
                <td>{{rep.numero}}</td>
                <td>{{rep.item_titre}}</td>
                <td>{{rep.item_auteur}}</td>
                <td>{{rep.item_editeur}}</td>
                <td>{{rep.date}}</td>
              </tr>
              </thead>
              <tbody>
              </tbody>
              <tfoot>
              </tfoot>
            </table>
            <div class="row">
              <mat-card-actions align="end">
                <button class="btn btn-danger btn-sm ml-1 float-right"  data-toggle="modal" data-target="#modal-supprimer" ><i class="typcn typcn-trash menu-icon"></i> {{ 'btn.supprimer' | translate}}</button>
                <button class="btn btn-success btn-sm ml-1 float-right"  data-toggle="modal" data-target="#modal-enregistrer" > <i class="typcn typcn-tick"></i> {{ 'btn.enregistrer' | translate}}</button>
              </mat-card-actions>
            </div>
          </div>
        </div>
        <!-- Modal pour supprimer-->
        <div class="modal fade" id="modal-supprimer" tabindex="-1" role="dialog" aria-labelledby="modal-enregistrer" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h2 class="modal-title" id="modal-supprimer-Label">{{'message.confirmer-titre'| translate}}</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div class="modal-body">
                <h3>{{'message.supprimer-text'| translate}}</h3>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal"> <i class="icon-close btn-icon-prepend"></i> {{'btn.annuler'| translate}}</button>
                <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="delete(panier.idPanier)"><i class="fa fa-trash btn-icon-prepend"></i> {{'btn.supprimer'| translate}}</button>
              </div>
            </div>
          </div>
        </div>
        <!-- fin modal-->
        <div class="noVisible" *ngFor="let panier of panier$ | async"></div>
      </div>
    </div>
  </div>
</div>
