<!-- Integrer le header -->
<nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row" >
  <div class="navbar-brand-wrapper d-flex justify-content-center">
    <div class="navbar-brand-inner-wrapper d-flex justify-content-between align-items-center w-100">
      <a class="navbar-brand brand-logo" href="/accueil"><img src="assets/images/biblio-logo.png" alt="logo"/></a>
      <a class="navbar-brand brand-logo-mini" href="/accueil"><img src="assets/images/favicon.ico" alt="logo"/></a>
      <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
        <span class="typcn typcn-th-menu"></span>
      </button>
    </div>
  </div>
  <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
    <ul class="navbar-nav mr-lg-2">
      <li class="nav-item nav-profile dropdown">
        <a class="nav-link" >
          <img src="assets/images/udem-projet/nom-projet-blanc.PNG" alt="profile"/>
        </a>
      </li>
    </ul>
    <ul class="navbar-nav navbar-nav-right">
      <li class="nav-item dropdown mr-0" *ngIf="panierService.getPanier().length>0">
        <a class="nav-link count-indicator bg-warning text-white dropdown-toggle d-flex align-items-center justify-content-center" id="notificationDropdown" href="#" data-toggle="dropdown" aria-expanded="false">
          <i class="typcn typcn-shopping-cart menu-icon mx-0"></i>
          <span class="count-panier"> {{panierService.getPanier().length}}</span>
        </a>
        <div  class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
          <div class="panier-list">
              <ul class="list-star list-group list-group-flush">
                <li *ngFor="let item of panierService.getPanier()" class="list-group-item">{{ item.titre }}</li>
              </ul>
            <br>
            <a href="/add-panier" class="link-panier btn-outline-secondary btn-sm float-left">{{ 'panier.voir' | translate}}</a>
            <a href="javascript:;" (click)="panierService.clearPanierLocalStorage()"  class="link-panier btn-outline-danger btn-sm float-right">{{ 'btn.supprimer' | translate}}</a>
          </div>
        </div>
      </li>
      <li class="nav-item nav-date dropdown">
        <a class="nav-link d-flex justify-content-center align-items-center" href="javascript:;">
          <i class="typcn typcn-calendar"></i>
          <h6 class="date mb-0"> {{currentDate   | date:'EEEE, d MMMM'}}</h6>
        </a>
      </li>
      <li class="nav-item nav-profile dropdown">
        <a class="nav-link" href="#" data-toggle="dropdown" id="profileDropdown">
          <p class="mb-0"> {{prenomAdmin}} {{nomAdmin}}</p>
        </a>
        <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
          <a (click)="logout()" class="dropdown-item"><i class="typcn typcn-eject text-primary"></i> {{'login.logout-text' | translate }}</a>
        </div>
      </li>
    </ul>
    <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
      <span class="typcn typcn-th-menu"></span>
    </button>
  </div>
</nav>


