<nav class="sidebar sidebar-offcanvas mt-5" id="sidebar" >
  <ul class="nav" >
    <li class="nav-item">
      <a class="nav-link" href="/accueil">
        <mat-icon>home</mat-icon>
        <span class="menu-title ml-1">{{ 'menu.accueil' | translate}}</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="/items" >
        <mat-icon>find_in_page</mat-icon>
        <span class="menu-title ml-1">{{ 'menu.rechercher' | translate}}</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="/items/add" >
        <mat-icon>move_to_inbox</mat-icon>
        <span class="menu-title ml-1">{{ 'menu.add-items' | translate}}</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="/historique-list" >
        <mat-icon>library_books</mat-icon>
        <span class="menu-title ml-1">{{ 'menu.historique' | translate}}</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link collapsed" data-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
        <mat-icon>settings_applications</mat-icon>
        <span class="menu-title"> {{ 'menu.outils' | translate}}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="collapse" id="ui-basic" style="">
        <ul class="nav flex-column sub-menu">
          <li class="nav-item"> <a class="nav-link" href="/collection">{{ 'menu.collections' | translate}}</a></li>
        </ul>
      </div>
    </li>
  </ul>
</nav>
