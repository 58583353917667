<div *ngIf="!isUserLinkRoute; else simpleView" class="container-scroller">
  <app-header *ngIf="ifAdmin"></app-header>
  <div  [ngClass]="{'container-fluid page-body-wrapper': ifAdmin}">
    <app-menu *ngIf="ifAdmin"></app-menu>
    <div [ngClass]="{'main-panel': ifAdmin}">
      <div   [ngClass]="{'content-wrapper': ifAdmin}">
        <router-outlet></router-outlet>
      </div>
      <app-footer *ngIf="ifAdmin"></app-footer>
    </div>
  </div>
</div>

<ng-template #simpleView>
  <router-outlet></router-outlet>
</ng-template>
