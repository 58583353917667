<!-- Contenu de la page -->
<div class="row justify-content-center mt-5">
  <div class="col-md-12 text-center mt-5">
    <span class="display-1 font-size-5 d-block mt-5">404</span>
    <div class="mb-4 lead"><h1>Opps!</h1>{{ 'codes.404' | translate}}</div>
    <button type="button" class="btn btn-danger btn-lg" routerLink="/accueil">
      {{ 'not-user-lien' | translate}}
    </button>
  </div>
</div>

