<div class="bg-white">
  <section class="top">
    <div class="title p-5 text-center">
      <h1>{{ 'app-title' | translate }}</h1>
      <h5 class="mt-2">{{ 'home.description' | translate }}</h5>
    </div>
  </section>

  <section class="page bg-white">
    <div class="parallax2 position-relative">
      <div class="overlay"></div>
      <h2 class="text-center page-title text-white">{{ 'not-user-title' | translate }}</h2>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 paragraph second text-center">
        <!--
        <div class="alert alert-warning text-center">
          <small>{{ 'user-explication' | translate }}: <a href="mailto:service@bib.umontreal.ca">service@bib.umontreal.ca</a></small>
        </div>
        -->
        <p class="mt-3">
          <a href="https://bib.umontreal.ca/" class="btn btn-outline-dark mt-2">{{ 'not-user-lien' | translate }}</a>
          <a href="/api/user-udem/login" class="btn btn-outline-success mt-2 ml-2">{{ 'connexion' | translate }}</a>
        </p>
      </div>
    </div>
  </section>
</div>
